import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticStyle:{"height":"70vh"},attrs:{"fluid":""}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Link preview "),_c(VSpacer)],1),_c(VCardText,[(_vm.linkData)?_c('span',{staticStyle:{"font-size":"1.5em"}},[_c('a',{attrs:{"href":'https://nush.link/'+ _vm.alias,"target":"_blank"}},[_vm._v("nush.link/"+_vm._s(_vm.alias))]),_vm._v(" points to "),_c('a',{attrs:{"href":_vm.linkData.url,"target":"_blank"}},[_vm._v(_vm._s(_vm.linkData.url))])]):_vm._e(),_c('br'),_c('br'),(_vm.linkData)?_c('span',[_vm._v(" Created by "),_c('b',[_vm._v(_vm._s(_vm.linkData.creator))]),_vm._v(" on "),_c('b',[_vm._v(_vm._s(new Date(_vm.linkData.createdOn).toLocaleDateString()))])]):_vm._e(),(_vm.error)?_c('span',[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }