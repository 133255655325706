import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,[(!_vm.success && !_vm.error)?_c(VCardTitle,[_vm._v(" Authorising... "),_c(VSpacer),_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),(_vm.success)?_c(VCardTitle,[_vm._v(" Student Authorised! "),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1):_vm._e(),(!_vm.success && _vm.error.length)?_c(VCardTitle,[_vm._v(" Oops, an error occurred! "),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1):_vm._e(),(!_vm.success && _vm.error.length)?_c(VCardText,[_c(VRow,{staticStyle:{"font-size":"1.25em"}},[_c(VCol,{staticClass:"error--text",attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm.error)+" ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }